<template>
  <div>
    <!-- 授权对话框 -->
    <el-dialog title="角色授权" :visible.sync="jurisdictionDialogFormVisible" width="30%">
      <el-tree
        ref="tree"
        :data="menuList"
        :show-checkbox="true"
        default-expand-all
        node-key="menuId"
        :expand-on-click-node="false"
        :props="defaultProps"
        @check-change="changeCheck"
      ></el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click="jurisdictionDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="jurisdictionBtn">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import to from 'await-to'
import { getMenu, getRoleJurisdiction, getSureJurisdiction } from '@/api/college'
export default {
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'menuName',
      },
      jurisdictionDialogFormVisible: false,
      idArr: [],
      menuList: [],
      clientCode: '0a983d86-2527-5512-01a9-783cd0e1',
      parentIds: [],
      childIds: [],
      roleId: '',
    }
  },
  created() {},
  methods: {
    show(roleId) {
      this.roleId = roleId
      this.getMenuData(roleId)
      this.jurisdictionDialogFormVisible = true
    },
    async getMenuData(roleId) {
      const [res, err] = await to(getMenu({ clientCode: this.clientCode }))
      if (err) return this.$message.warning(err.msg)
      this.menuList = res.data
      this.getRoleJurisdictionData(roleId)
    },
    async getRoleJurisdictionData(roleId) {
      const [res, err] = await to(
        getRoleJurisdiction({ clientCode: this.clientCode, roleId: roleId }),
      )
      if (err) return this.$message.warning(err.msg)
      this.$refs.tree.setCheckedKeys(res.data)
    },
    changeCheck() {
      this.childIds = this.$refs.tree.getCheckedNodes()
      this.parentIds = this.$refs.tree.getHalfCheckedKeys()
      //   console.log(this.childIds)
      //   console.log(this.parentIds)
    },
    async jurisdictionBtn() {
      let arr = []
      this.childIds.map(v => arr.push(v.menuId))
      const [, err] = await to(
        getSureJurisdiction({
          clientCode: this.clientCode,
          roleId: this.roleId,
          menuIds: arr,
          parentMenuIds: this.parentIds,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      this.$message.success('授权成功')
      this.jurisdictionDialogFormVisible = false
      this.$emit('success')
      this.$refs.tree.setCheckedKeys([])
    },
  },
}
</script>

<style scoped></style>
