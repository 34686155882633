<template>
  <div>
    <table-list
      title="角色管理"
      :loading="loading"
      :data="roleList"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true }"
      @search="onSearch"
      @size-change="handleSizeChange"
      @selectionChange="handleSelectionChange"
      @current-change="handleCurrentChange"
    ></table-list>

    <el-dialog
      :title="status == 1 ? '添加角色' : '编辑角色'"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="角色名称：" prop="roleName">
          <el-input v-model="form.roleName" placeholder="请输入角色名称" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取消</el-button>
        <el-button type="primary" @click="handleSubmit('form')">确定</el-button>
      </div>
    </el-dialog>

    <Jurisdiction ref="Jurisdiction" @success="getAllRoleData()"></Jurisdiction>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '添加角色',
    method: _this.handleAdd,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'primary',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'roleName',
    label: '角色名称',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '授权',
    method: _this.handleJurisdiction,
  },
  {
    label: '编辑',
    method: _this.handleEdit,
  },
]
const listObj = {
  roleName: '',
}
import TableList from '@/components/TableList'
import Jurisdiction from './Jurisdiction'
import { getAllRole, getAddRole, getEditRole, getBatchDelRole } from '@/api/college'
import to from 'await-to'
export default {
  name: 'CollegeAdmin',
  components: {
    TableList,
    Jurisdiction,
  },
  data() {
    return {
      btns,
      columns,
      operates,
      loading: false,
      roleList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      select: [],
      queryParams: {},
      clientCode: '0a983d86-2527-5512-01a9-783cd0e1',
      dialogVisible: false,
      status: 0,
      form: {
        roleName: '',
        roleId: '',
      },
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 2, max: 10, message: '请输入2-10字角色名称' },
        ],
      },
      menuList: [],
    }
  },
  created() {
    this.getAllRoleData()
  },
  methods: {
    async getAllRoleData() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(getAllRole({ clientCode: this.clientCode }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.roleList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getAllRoleData()
    },
    async handleSubmit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.status == 1) {
            const [, err] = await to(
              getAddRole({ clientCode: this.clientCode, roleName: this.form.roleName }),
            )
            if (err) return this.$message.warning(err.msg)
            this.$message.success('添加成功')
          } else {
            const [, err] = await to(getEditRole({ clientCode: this.clientCode, ...this.form }))
            if (err) return this.$message.warning(err.msg)
            this.$message.success('编辑成功')
          }
          this.$refs[formName].resetFields()
          this.dialogVisible = false
          this.getAllRoleData()
        }
      })
    },
    closeDialog() {
      if (this.status == 2) {
        this.form = Object.assign({}, listObj)
      }
    },
    cancel() {
      this.dialogVisible = false
      this.closeDialog()
    },
    //添加
    handleAdd() {
      this.status = 1
      this.dialogVisible = true
    },
    // 编辑
    handleEdit(row) {
      this.status = 2
      this.dialogVisible = true
      this.form = Object.assign({}, row)
    },
    //授权
    handleJurisdiction(row) {
      this.$refs.Jurisdiction.show(row.roleId)
    },
    // 删除
    async handleDelete() {
      if (!this.select.length) return this.$message.warning('请先选择要删除的角色')
      let arr = []
      this.select.map(v => arr.push(v.roleId))
      this.$confirm('你确认要删除吗?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const [, err] = await to(getBatchDelRole({ ids: arr }))
          if (err) return this.$message({ type: 'error', message: err.msg })
          this.$message({ type: 'success', message: '删除成功' })
          this.getAllRoleData()
        })
        .catch(() => {})
    },
    handleSelectionChange(val) {
      this.select = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getAllRoleData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getAllRoleData()
    },
  },
}
</script>
